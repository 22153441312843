import React from "react";
import PropTypes from "prop-types";

interface CardStatsProps {
  statSubtitle: string;
  statTitle: string;
  statPercent: string;
  statDescription?: string;
  onClick: Function;
  className?: any;
}

const CardPois: React.FC<CardStatsProps> = ({
  statSubtitle,
  statTitle,
  statPercent,
  statDescription = "",
  onClick,
  className,
}) => {
  return (
    <div
      className={`${className} w-full bg-white rounded mb-6s shadow-lg cursor-pointer`}
      onClick={() => onClick()}
    >
      <div className="flex-auto p-4">
        <div className="flex flex-wrap items-center justify-center ">
          <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
            <h5 className="text-blueGray-400 uppercase font-bold text-xs">
              {statSubtitle}
            </h5>
            <span className="font-semibold text-xl text-blueGray-700">
              {statTitle}
            </span>
          </div>
        </div>
        <p className="text-sm text-blueGray-400 mt-4">
          <span className={`mr-2`}>{statPercent}</span>
          <span className="whitespace-nowrap">{statDescription}</span>
        </p>
      </div>
    </div>
  );
};

CardPois.propTypes = {
  statSubtitle: PropTypes.string.isRequired,
  statTitle: PropTypes.string.isRequired,
  statPercent: PropTypes.string.isRequired,
  statDescription: PropTypes.string,
  onClick: PropTypes.func.isRequired, // Aseguramos que onClick sea una función requerida
};

export default CardPois;
