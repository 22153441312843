import React, { useEffect, useState } from "react";
import { getReportData } from "../../apis/Reports";
import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import { ARModalHeader } from "../CustomComponents/ARModal/ARModalHeader";
import AdminMap from "../Maps/AdminMap";
import ModalAssignReport from "./ModalAssignReport";
import ModalFollowReport from "./ModalFollowReport";
import { IReport } from "../../interfaces/IReports";

interface ModalViewReportMapProps {
  open: boolean;
  setOpen: Function;
  action?: () => void;
  report?: IReport;
}

const ModalViewReportMap: React.FC<ModalViewReportMapProps> = ({
  open,
  setOpen,
  action,
  report,
}) => {
  const [openAssign, setOpenAssign] = useState<boolean>(false);
  const [openFollow, setOpenFollow] = useState<boolean>(false);
  const [reportData, setReportData] = useState<IReport[]>([]);
  const [status, setStatus] = useState({ id: "", name: "" });

  const handleAccept = () => {
    if (action && setOpen) {
      action();
      setOpen(false);
    }
  };

  useEffect(() => {
    getReportData(report?.id, setReportData);
  }, [open]);

  return (
    <React.Fragment>
      <ARModal open={open} setOpen={setOpen}>
        <ARModalHeader className={"text-3xl items-center"}>
          <i
            className="fas fa-times pr-4 cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          />
          {report?.title}
        </ARModalHeader>
        <ARModalBody>
          <AdminMap report={report} />
        </ARModalBody>
        {!["Cancelado", "Resuelto"].includes(report?.status?.name!) && (
          <ARModalActions>
            {!report?.group && (
              <button
                type="button"
                className="w-full justify-center rounded-md px-2 py-1 text-sm font-semibold shadow-sm hover:bg-gray-200 ml-3 w-auto ring-1 ring-inset ring-gray-300"
                // onClick={() => setOpenAssign(true)}
                aria-label="Asignar Reporte"
              >
                Añadir reporte a grupo
              </button>
            )}
            {/* <button
              type="button"
              className="w-full justify-center rounded-md px-2 py-1 text-sm font-semibold shadow-sm hover:bg-gray-200 ml-3 w-auto ring-1 ring-inset ring-gray-300"
              onClick={() => setOpenAssign(true)}
              aria-label="Asignar Reporte"
            >
              Asignar Reporte
            </button> */}
            <button
              type="button"
              className="w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 ml-3 w-auto"
              onClick={() => {
                setStatus({ id: "", name: "Cancelado" });
                setOpenFollow(true);
              }}
              aria-label="Rechazar Reporte"
            >
              {report?.status.name === "En Proceso" ? "Cancelar" : "Rechazar"}
            </button>
            <button
              type="button"
              className="w-full justify-center rounded-md bg-green-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-green-500 ml-3 w-auto"
              onClick={() => {
                setStatus(report?.status!);
                setOpenFollow(true);
              }}
              aria-label="Aceptar Reporte"
            >
              {report?.status.name === "En Proceso" ? "Finalizar" : "Aceptar"}
            </button>
          </ARModalActions>
        )}
      </ARModal>

      <ModalAssignReport open={openAssign} setOpen={setOpenAssign} />
      <ModalFollowReport
        open={openFollow}
        setOpen={setOpenFollow}
        status={status}
        report={report!}
      />
    </React.Fragment>
  );
};

export default ModalViewReportMap;
