import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import { useEffect, useState } from "react";
import { getData } from "../../apis/Get";
import { ARModalHeader } from "../CustomComponents/ARModal/ARModalHeader";
import { ARTypography } from "../CustomComponents/ARTypography";
import { changeReportStatus } from "../../apis/Reports";
import { IReport } from "../../interfaces/IReports";

export default function ModalFollowReport({
  open,
  setOpen,
  status,
  report,
}: {
  open: boolean;
  setOpen: Function;
  status: { id: string; name: string };
  report: IReport;
}) {
  const [statuses, setStatuses] = useState<{ id: string; name: string }[]>([]);
  const [nextStatus, setNextStatus] = useState({ id: "", name: "" });
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState({ id: "", name: "" });

  useEffect(() => {
    // getData("/user", setUsuarios);
    getData("/admin/reportstatus", setStatuses);
  }, [open]);

  useEffect(() => {
    if (status.name === "Solicitud") {
      setNextStatus(
        statuses.filter((newStatus) => newStatus?.name === "En Proceso")[0]
      );
    } else if (status.name === "En Proceso") {
      setNextStatus(
        statuses.filter((newStatus) => newStatus?.name === "Resuelto")[0]
      );
    } else if (status.name === "Cancelado") {
      setNextStatus(
        statuses.filter((newStatus) => newStatus?.name === "Cancelado")[0]
      );
    } else {
      setNextStatus(
        statuses.filter((newStatus) => newStatus?.name === "En Proceso")[0]
      );
    }
  }, [statuses, status]);

  const confirm = () => {
    const reportData = {
      ...report,
      status_id: nextStatus?.id,
      report_type_id: report.type?.id,
      priority_id: report.priority?.id,
      user_id: report.user?.id,
    };
    changeReportStatus(report?.id, reportData);
  };

  return (
    <ARModal open={open} setOpen={setOpen}>
      <ARModalHeader>
        <ARTypography value={"Confirmar cambio de estado"} />
      </ARModalHeader>
      <ARModalBody>
        {`Al aceptar el estado del reporte cambiará a: `}
        <span
          className={`${
            nextStatus?.name === "Solicitud"
              ? "text-blue-500"
              : nextStatus?.name === "En Proceso"
              ? "text-yellow-500"
              : nextStatus?.name === "Resuelto"
              ? "text-green-500"
              : nextStatus?.name === "Cancelado"
              ? "text-red-500"
              : "text-blueGray-500"
          }`}
        >
          {nextStatus?.name}
        </span>
        {/* {!["Cancelado", "Resuelto"].includes(status.name) && (
          <ARSelect
            label="Selecciona usuario si deseas asignar el reporte"
            options={usuarios}
            value={usuario.id}
            onChange={(e) => {
              setUsuario({ ...usuario, id: e.target.value });
            }}
            size="lg"
            sx="mt-4"
          />
        )} */}
      </ARModalBody>
      <ARModalActions>
        <button
          type="button"
          className="w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 ml-3 w-auto"
          aria-label="Rechazar Reporte"
          onClick={() => {
            setNextStatus({ id: "", name: "" });
            setOpen(false);
          }}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="w-full justify-center rounded-md bg-green-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-green-500 ml-3 w-auto"
          onClick={confirm}
          aria-label="Aceptar Reporte"
        >
          Aceptar
        </button>
      </ARModalActions>
    </ARModal>
  );
}
