import { ISelect } from "../../interfaces/ISelect";
import { SizeTokens } from "../../styling/Size";

export const ARSelect = ({
  label = "",
  options = [],
  value = undefined,
  onChange,
  size = "m",
  helperText = "",
  sx,
  disabled,
}: ISelect) => {
  return (
    <div className={`${sx} ${SizeTokens.Select[size]} px-4`}>
      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="select-field"
        >
          {label}
        </label>

        <select
          className="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          value={value}
          onChange={onChange}
          id="select-field"
          disabled={disabled}
        >
          <option value="" disabled>
            Selecciona una opción
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.id}>
              {option.value || option.name}
            </option>
          ))}
        </select>

        <small id="selectHelp" className="form-text text-muted">
          {helperText}
        </small>
      </div>
    </div>
  );
};
