import { Link } from "react-router-dom";
import { links } from "../../App";

export const Sidebar = ({
  open,
  setOpen,
}: {
  open?: boolean;
  setOpen: Function;
}) => {
  return (
    <>
      <div
        className={`fixed z-50 inset-y-0 left-0 bg-gray-800 text-white transition-transform duration-300 ${
          open ? "translate-x-0" : "-translate-x-full"
        } w-64`}
      >
        <div className="container flex flex-wrap items-center py-5 px-4">
          <button className="m-2 text-2xl" onClick={() => setOpen(!open)}>
            {open ? (
              <i className="fas fa-times-circle text-white" />
            ) : (
              <i className="fas fa-bars text-white" />
            )}
          </button>
          <div className="relative flex justify-between">
            <Link
              to="/"
              className="text-white text-xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              App Reporte
            </Link>
          </div>
        </div>
        <div className="relative">
          <span
            className={
              "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
            }
          >
            Inicio
          </span>
          <Link
            to="/landing"
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent"
          >
            Landing
          </Link>
          <Link
            to={links.admin.dashboard}
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent"
          >
            Dashboard
          </Link>
          <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
          <span
            className={
              "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
            }
          >
            Reportes
          </span>
          <Link
            to={links.reports}
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent"
          >
            Tablas de Reportes
          </Link>
          <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
          <span
            className={
              "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
            }
          >
            Puntos de interés
          </span>
          <Link
            to={links.reviews}
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent"
          >
            Reseñas
          </Link>
          <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />

          <span
            className={
              "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
            }
          >
            Vistas de administrador
          </span>
          <Link
            to={links.admin.poiCatalogs}
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent"
          >
            Catálogos de puntos de interés
          </Link>
          <Link
            to={links.admin.settings}
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent"
          >
            Configuración
          </Link>
          <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
          <span
            className={
              "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
            }
          >
            Auth Layout
          </span>
          <Link
            to="/auth/login"
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent"
          >
            Login
          </Link>
          <Link
            to="/auth/register"
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent"
          >
            Register
          </Link>
          <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
          <span
            className={
              "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
            }
          >
            No Layout
          </span>
          <Link
            to={links.profile}
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent"
          >
            Perfil
          </Link>

          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
              <a
                className="hover:text-blueGray-500 text-white px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="https://app.jonhylafarm.net/"
              >
                <i className="text-blueGray-400 fab fa-facebook text-lg leading-lg " />
                <span className="lg:hidden inline-block ml-2">Share</span>
              </a>
            </li>

            <li className="flex items-center">
              <a
                className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="https://app.jonhylafarm.net/"
              >
                <i className="text-blueGray-400 fab fa-twitter text-lg leading-lg " />
                <span className="lg:hidden inline-block ml-2">Tweet</span>
              </a>
            </li>

            <li className="flex items-center">
              <a
                className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="https://app.jonhylafarm.net/"
              >
                <i className="text-blueGray-400 fab fa-github text-lg leading-lg " />
                <span className="lg:hidden inline-block ml-2">Star</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
