import React, { useEffect, useState } from "react";
import { ARCard } from "../components/CustomComponents/ARCard/ARCard";
import { ARCardBody } from "../components/CustomComponents/ARCard/ARCardBody";
import ModalViewReportMap from "../components/Modals/ModalViewReportMap";
import Navbar from "../components/Navbars/IndexNavbar";
import { getData } from "../apis/Get";
import { ARTypography } from "../components/CustomComponents/ARTypography";
import PrivateRoute from "../layouts/PrivateRoute";
import { IPointOfInterest } from "../components/POIs/PoiReviews";
import { IReport } from "../interfaces/IReports";

export interface IIncidentReport {
  id: string;
  title: string;
  description: string;
  latitude: string;
  longitude: string;
  created_at: string;
  priority: {
    id: string;
    name: string;
  };
  status: {
    id: string;
    name: string;
  };
  type: {
    id: string;
    name: string;
    icon: string;
  };
  group: string;
  user: {
    id: string;
    username: string;
  };
}

export interface IPoiReport {
  id: string;
  name: string;
  description: string;
  category: { id: string; name: string };
  latitude: string;
  longitude: string;
  rating?: number;
  type: { icon: string; id: string; name: string };
  user?: { id: string; username: string };
}

const orderedIncidenceTypeColumns = {
  id: "Id",
  created_at: "Fecha del reporte",
  type: "Tipo de Reporte",
};

const orderedIncidenceStatusColumns = {
  id: "Id",
  created_at: "Fecha del reporte",
  status: "Estatus",
};
// const orderedIncidenceColumns = {
//   id: "Id",
//   title: "Título",
//   description: "Descripción",
//   latitude: "Latitud",
//   longitude: "Longitud",
//   created_at: "Fecha del reporte",
//   priority: "Prioridad",
//   status: "Estatus",
//   type: "Tipo de Reporte",
//   group: "Grupo",
//   user: "Reportado por",
// };

const orderedPoiCommentColumns = {
  id: "Id",
  rating: "Calificación",
};

const orderedPoiViewsColumns = {
  id: "Id",
  rating: "Calificación",
};

// const orderedPoiColumns = {
//   id: "Id",
//   name: "Nombre",
//   description: "Descripción",
//   rating: "Calificación",
//   category: "Categoría",
//   type: "Tipo",
//   latitude: "Latitud",
//   longitude: "Longitud",
//   user: "Usuario de alta",
// };

const Reports: React.FC = () => {
  const [reports, setReports] = useState<IIncidentReport[]>([]);
  const [pointsOfInterest, setPointsOfInterest] = useState<IPointOfInterest[]>(
    []
  );
  const [selectedReport, setSelectedReport] = useState<
    IIncidentReport | undefined
  >(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [date, setDate] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth()}`
  );

  const handleOpenModal = (report: IIncidentReport) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getData("/reports", setReports);
    getData("/pointofinterest", setPointsOfInterest);
  }, []);

  return (
    <PrivateRoute>
      <Navbar />
      <div className="flex flex-col justify-center items-center w-full px-4 py-4">
        <form lang="es" className="flex flex-col items-center mb-4">
          <div className="flex justify-center items-center bg-gray-100">
            <div className="max-w-md w-full p-2 bg-white shadow-md rounded-lg">
              <label
                htmlFor="month-year"
                className="block text-lg font-medium text-gray-700 mb-2"
              >
                Filtro mes / año
              </label>
              <input
                type="month"
                id="month-year"
                name="month-year"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>
          </div>
        </form>

        <div className="w-11/12 sm:flex sm:flex-col lg:grid lg:grid-cols-4 sm:h-full lg:h-[40rem] overflow-auto justify-items-center">
          <ARCard className="h-full overflow-auto w-full">
            <ARCardBody>
              <h2 className="text-2xl font-semibold leading-tight">
                Tipo de incidencia
              </h2>
              <ReportsTable
                reports={reports}
                onReportClick={handleOpenModal}
                isFilterVisible={isFilterVisible}
                columns={Object.values(orderedIncidenceTypeColumns)}
                rows={Object.keys(orderedIncidenceTypeColumns)}
              />
            </ARCardBody>
          </ARCard>

          <ARCard className="h-full overflow-auto w-full">
            <ARCardBody>
              <h2 className="text-2xl font-semibold leading-tight">Estatus</h2>
              <ReportsTable
                reports={reports}
                onReportClick={handleOpenModal}
                isFilterVisible={isFilterVisible}
                columns={Object.values(orderedIncidenceStatusColumns)}
                rows={Object.keys(orderedIncidenceStatusColumns)}
              />
            </ARCardBody>
          </ARCard>

          <ARCard className="h-full overflow-auto w-full">
            <ARCardBody>
              <h2 className="text-2xl font-semibold leading-tight">
                Comentarios
              </h2>
              <ReviewsTable
                pois={pointsOfInterest}
                onPoiClick={() => {}}
                isFilterVisible={isFilterVisible}
                columns={Object.values(orderedPoiCommentColumns)}
                rows={Object.keys(orderedPoiCommentColumns)}
              />
            </ARCardBody>
          </ARCard>

          <ARCard className="h-full overflow-auto w-full">
            <ARCardBody>
              <h2 className="text-2xl font-semibold leading-tight">Vistas</h2>
              <ReviewsTable
                pois={pointsOfInterest}
                onPoiClick={() => {}}
                isFilterVisible={isFilterVisible}
                columns={Object.values(orderedPoiViewsColumns)}
                rows={Object.keys(orderedPoiViewsColumns)}
              />
            </ARCardBody>
          </ARCard>
        </div>
      </div>
      {isModalOpen && (
        <ModalViewReportMap
          open={isModalOpen}
          setOpen={setIsModalOpen}
          report={selectedReport as IReport}
        />
      )}
    </PrivateRoute>
  );
};

const SearchBar: React.FC<{
  isFilterVisible: boolean;
  setIsFilterVisible: (val: boolean) => void;
}> = ({ isFilterVisible, setIsFilterVisible }) => (
  <div className="my-2 flex flex-col sm:flex-row items-center relative">
    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
      <i className="far fa-search"></i>
    </span>
    <input
      placeholder="Buscar"
      className="appearance-none rounded-l border border-gray-400 block pl-8 pr-6 py-2 w-full sm:w-60 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
    />
  </div>
);

const ReviewsTable: React.FC<{
  pois: IPointOfInterest[];
  onPoiClick: (poi?: IPointOfInterest) => void;
  isFilterVisible?: boolean;
  columns: string[];
  rows: string[];
}> = ({ pois, onPoiClick, isFilterVisible, columns, rows }) => {
  return (
    <div className="py-4 overflow-auto">
      <div className="inline-block shadow rounded-lg overflow-hidden">
        <table className="leading-normal w-full">
          <thead>
            <tr>
              {columns.map(
                (column) =>
                  column !== "Id" && (
                    <th
                      key={column}
                      className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      {column}
                    </th>
                  )
              )}
            </tr>
          </thead>
          <tbody>
            {pois.length > 0 ? (
              pois.map((poi) => (
                <tr key={poi.id}>
                  {Object.entries(poi).map(
                    ([key, value]) =>
                      key !== "id" &&
                      rows.includes(key) &&
                      (key === "created_at" ? (
                        <td
                          key={key}
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p
                            className={`text-black-900 whitespace-no-wrap p-1 `}
                          >
                            {`${new Date(value).getDate()}/${new Date(
                              value
                            ).getMonth()}/${new Date(value).getFullYear()}`}
                          </p>
                        </td>
                      ) : (
                        <td
                          key={key}
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p
                            className={`text-black-900 whitespace-no-wrap p-1 `}
                          >
                            {value?.name || value}
                          </p>
                        </td>
                      ))
                  )}
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <a
                      href="#"
                      className="font-medium text-blue-600 hover:underline"
                      onClick={() => onPoiClick(poi)}
                    >
                      <i className="fas fa-search-location text-lg"></i>
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <ARTypography value={"Sin registros"} />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ReportsTable: React.FC<{
  reports: IIncidentReport[];
  onReportClick: (report: IIncidentReport) => void;
  isFilterVisible?: boolean;
  columns: string[];
  rows: string[];
}> = ({ reports, onReportClick, isFilterVisible, columns, rows }) => {
  return (
    <div className="py-4 overflow-auto">
      <div className="inline-block shadow rounded-lg overflow-hidden">
        <table className="leading-normal w-full">
          <thead>
            <tr>
              {columns.map(
                (column) =>
                  column !== "Id" && (
                    <th
                      key={column}
                      className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      {column}
                    </th>
                  )
              )}
            </tr>
            {/* {isFilterVisible && (
              <tr>
                {columns.map((column) => (
                  <th
                    key={column}
                    className="px-1 py-1 border-b-2 border-gray-200 bg-gray-100 text-xs text-gray-600"
                  >
                    <input
                      placeholder={`Filtrar ${column}`}
                      className="w-full"
                    />
                  </th>
                ))}
              </tr>
            )} */}
          </thead>
          <tbody>
            {reports.length > 0 ? (
              reports.map((report, index) => (
                <tr key={index}>
                  {Object.entries(report).map(
                    ([key, value], index) =>
                      key !== "id" &&
                      rows.includes(key) &&
                      (key === "created_at" ? (
                        <td
                          key={key}
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p
                            className={`text-black-900 whitespace-no-wrap p-1 `}
                          >
                            {`${new Date(value).getDate()}/${new Date(
                              value
                            ).getMonth()}/${new Date(value).getFullYear()}`}
                          </p>
                        </td>
                      ) : (
                        <td
                          key={key}
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p
                            className={`text-black-900 whitespace-no-wrap p-1 `}
                          >
                            {value?.name || value}
                          </p>
                        </td>
                      ))
                  )}
                  <td className="pr-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <a
                      href="#"
                      className="font-medium text-blue-600 hover:underline"
                      onClick={() => onReportClick(report)}
                    >
                      <i className="fas fa-search-location text-lg"></i>
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <ARTypography value={"Sin registros"} />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Reports;
