export const ARModalHeader = ({
  children,
  className,
}: {
  children?: any;
  className?: string;
}) => {
  return (
    <div className={`bg-gray-50 px-4 pt-3 flex ${className}`}>{children}</div>
  );
};
