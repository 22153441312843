import React, { useState } from "react";
import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import AddPoiMap from "../Maps/AddPoiMap";
import { IPointOfInterest } from "../POIs/PoiReviews";
import ModalAddPoiDescription from "./ModalAddPoiDescription";

interface ModalAddPoiProps {
  open: boolean;
  setOpen: Function;
}

const ModalAddPoi: React.FC<ModalAddPoiProps> = ({ open, setOpen }) => {
  const [openAddNew, setOpenAddNew] = useState<boolean>(false);

  const [poiInfo, setPoiInfo] = useState<IPointOfInterest>({
    name: "",
    description: "",
    category: { id: "", name: "" },
    latitude: "",
    longitude: "",
    rating: 0,
    type: { icon: "", id: "", name: "" },
    category_id: "",
    point_of_interest_type_id: "",
    user_id: localStorage.getItem("userId")!,
  });

  return (
    <React.Fragment>
      <ARModal open={open} setOpen={setOpen}>
        <ARModalBody>
          <AddPoiMap
            poiInfo={poiInfo}
            setLocation={setPoiInfo}
            setOpenAddNew={setOpenAddNew}
          />
        </ARModalBody>
        <ARModalActions>
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            onClick={() => {
              setPoiInfo({
                name: "",
                description: "",
                category: { id: "", name: "" },
                category_id: "",
                latitude: "",
                longitude: "",
                type: { icon: "", id: "", name: "" },
                point_of_interest_type_id: "",
                user_id: localStorage.getItem("userId")!,
              });
              setOpen(false);
            }}
          >
            Cancelar
          </button>
        </ARModalActions>
      </ARModal>
      <ModalAddPoiDescription
        open={openAddNew}
        setOpen={setOpenAddNew}
        poiInfo={poiInfo}
        setPoiInfo={setPoiInfo}
      />
    </React.Fragment>
  );
};

export default ModalAddPoi;
