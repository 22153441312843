import axios from "axios";
import { ICatalogo } from "../views/admin/Catalogs";
import { IPointOfInterest } from "../components/POIs/PoiReviews";

export const uploadImages = async (
  end: string,
  data: {
    user_id: string;
    report_id: string;
    photos: Array<string>;
  },
  set?: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "post",
      url: process.env.REACT_APP_APPLICATION_BACK + "/uploadphoto/" + end,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });

    set && set(response.data);
    alert(`Imágen subida con éxito`);
  } catch (error) {
    alert(`Error al actualizar el registro: ${error}`);
  }
};

export const createPoi = async (data: IPointOfInterest, set?: Function) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "post",
      url: process.env.REACT_APP_APPLICATION_BACK + "/pointofinterest",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });

    set && set(response.data);
    alert(`Elemento agregado con éxito`);
    window.location.reload();
  } catch (error) {
    alert(`Error al crear el registro: ${error}`);
  }
};

export const updatePoi = async (
  id: string,
  data: ICatalogo,
  set?: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "put",
      url: process.env.REACT_APP_APPLICATION_BACK + "/pointofinterest/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { name: data.name },
    });
    set && set(response.data);
    alert(`Elemento actualizado con éxito`);
    window.location.reload();
  } catch (error) {
    alert(`Error al actualizar el registro: ${error}`);
  }
};

export const deletePoi = async (id: string, set?: Function) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "delete",
      url: process.env.REACT_APP_APPLICATION_BACK + "/pointofinterest/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    set && set(response.data);
    alert(`Elemento eliminado con éxito`);
    window.location.reload();
  } catch (error) {
    alert(`Error al eliminar el registro: ${error}`);
  }
};

export const getRatings = async (id: string, set?: Function) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "get",
      url:
        process.env.REACT_APP_APPLICATION_BACK +
        "/pointofinterestratings/" +
        id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    set && set(response.data);
    alert(`Elemento actualizado con éxito`);
    window.location.reload();
  } catch (error) {
    alert(`Error al actualizar el registro: ${error}`);
  }
};

export const getImages = async (path: string, set?: Function) => {
  try {
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_APPLICATION_STORAGE + path,
    });

    set && set(response);
  } catch (error) {}
};
