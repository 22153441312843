import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { createIcon } from "../POIs/PoiReviews";
import { IReport } from "../../interfaces/IReports";

export const AdminMap = ({ report }: { report: IReport | undefined }) => {
  return (
    <div className="relative w-full rounded h-600-px">
      <MapContainer
        style={{ height: "600px", zIndex: "0" }}
        center={[Number(report?.latitude), Number(report?.longitude)]}
        zoom={16}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={[Number(report?.latitude), Number(report?.longitude)]}
          icon={createIcon("blue-500", "fa-map-marker-alt")}
        >
          <Popup>{report?.description}</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default AdminMap;
