import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvent,
} from "react-leaflet";
import { ARTypography } from "../CustomComponents/ARTypography";
import { createIcon, IPointOfInterest } from "../POIs/PoiReviews";

function AddPoiMap({
  poiInfo = {
    name: "",
    description: "",
    category: { id: "", name: "" },
    latitude: "",
    longitude: "",
    rating: 0,
    type: { icon: "", id: "", name: "" },
  },
  setLocation,
  setOpenAddNew,
}: {
  poiInfo: IPointOfInterest;
  setLocation: Function;
  setOpenAddNew: Function;
}) {
  const AddMarkerOnClick = () => {
    useMapEvent("click", (e) => {
      const { lat, lng } = e.latlng;

      setLocation({
        ...poiInfo,
        latitude: String(lat),
        longitude: String(lng),
      });

      setOpenAddNew(true);
    });
    return null;
  };

  return (
    <div className="w-full rounded h-600-px text-wrap">
      <ARTypography size="sm">Añadir Nuevo Punto de Interés</ARTypography>
      <ARTypography size="xs" sx="text-wrap">
        Da click en el mapa sobre la ubicación del punto de interés y añade los
        datos correspondientes
      </ARTypography>
      <MapContainer
        center={[25.67151333465, -100.3128631307]}
        zoom={14}
        style={{ height: "85%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <AddMarkerOnClick />
        <Marker
          key={poiInfo.id}
          position={[Number(poiInfo.latitude), Number(poiInfo.longitude)]}
          icon={createIcon("blue-500", "fa-map-marker-alt")}
        >
          <Popup>{poiInfo.description}</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default AddPoiMap;
