import L, { DivIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { ARCard } from "../../components/CustomComponents/ARCard/ARCard";
import Navbar from "../../components/Navbars/IndexNavbar";
import PrivateRoute from "../../layouts/PrivateRoute";
import CardPois from "../Cards/CardPois";
import { ARCardActions } from "../CustomComponents/ARCard/ARCardActions";
import { ARTypography } from "../CustomComponents/ARTypography";
import PoiDetail from "./PoiDetail";
import { getData } from "../../apis/Get";
import ModalAddPoi from "../Modals/ModalAddPoi";
import { IUsuario } from "../../views/admin/Users";

export interface IReview {
  id: number;
  text: string;
  rating: number;
}

export interface IRating {
  id: string;
  point_of_interest_id: string;
  estatus: { id: string; name: string };
  photo: Array<string>;
  photo_path: string;
  rating: string;
  rating_status_id: string;
  review: string;
  user: IUsuario;
  user_id: string;
}

export interface IPointOfInterest {
  id?: string;
  name: string;
  description: string;
  category: { id: string; name: string; icon_path?: string };
  category_id?: string;
  latitude: string;
  longitude: string;
  rating?: number;
  ratings?: Array<IRating>;
  type: { icon: string; id: string; name: string };
  point_of_interest_type_id?: string;
  user?: { id: string; username: string };
  user_id?: string;
  photos?: Array<{
    id: string;
    photo: string;
    photo_path: string;
    point_of_interest_photo_type_id: string;
  }>;
}

export const createIcon = (color: string, iconClass: string): DivIcon => {
  return L.divIcon({
    html: `<span class="fa-stack small text-${color}"><i class="fas fa-circle fa-stack-2x"></i><i class="fas ${iconClass} fa-stack-1x fa-inverse"></i></span>`,
  });
};

export const PoiReviews = () => {
  const [pointsOfInterest, setPointsOfInterest] = useState<IPointOfInterest[]>(
    []
  );
  const [selectedPOI, setSelectedPOI] = useState<IPointOfInterest | null>(null);
  const [openAddPoi, setOpenAddPoi] = useState(false);

  useEffect(() => {
    getData("/pointofinterest", setPointsOfInterest);
  }, []);

  const createIcon = (color: string, iconClass: string): DivIcon => {
    return L.divIcon({
      html: `<span class="fa-stack small text-${color}"><i class="fas fa-circle fa-stack-2x"></i><i class="fas ${iconClass} fa-stack-1x fa-inverse"></i></span>`,
    });
  };

  const calificacionEstrellas = (reviews: IReview[] | number): string => {
    if (!reviews) return "";
    let promedio = 0;
    if (Array.isArray(reviews)) {
      promedio = Math.floor(
        reviews.reduce((sum, review) => sum + review.rating, 0) /
          reviews?.length
      );
    } else {
      promedio = reviews;
    }
    return "⭐".repeat(promedio);
  };

  return (
    <PrivateRoute>
      <Navbar />
      <div
        className="lg:flex sm:flex-row w-full items-center justify-center"
        style={{ height: "87vh" }}
      >
        {!selectedPOI ? (
          <div className="lg:flex sm:flex-row w-full h-full items-center justify-center">
            <div className="lg:w-8/12 sm:w-full h-3/4 flex-center">
              <MapContainer
                style={{ height: "100%", zIndex: "0" }}
                center={[25.6736146, -100.3107736]}
                zoom={15}
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {pointsOfInterest.map((poi) => (
                  <Marker
                    key={poi.id}
                    position={[Number(poi.latitude), Number(poi.longitude)]}
                    icon={createIcon("blue-500", "fa-map-marker-alt")}
                  >
                    <Popup>
                      <div className="text-center">
                        <h3 className="font-bold">{poi.name}</h3>
                        {calificacionEstrellas(poi.rating!)}
                        <button
                          className="mt-2 text-blue-600 hover:underline"
                          onClick={() => setSelectedPOI(poi)}
                        >
                          Ver Reseñas
                        </button>
                      </div>
                    </Popup>
                  </Marker>
                ))}
              </MapContainer>
            </div>

            <div className="min-[320px]:flex flex-col min-[320px]:w-full lg:w-1/4 min-[320px]:h-1/5 lg:h-3/4 overflow-auto">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-semibold text-center ml-2">
                  Puntos de Interés
                </h2>
                <button
                  type="button"
                  data-tooltip-target="tooltip-add-poi"
                  className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2 mr-2"
                  onClick={() => {
                    setOpenAddPoi(true);
                  }}
                >
                  <i className="fas fa-plus"></i>
                </button>
                <div
                  id="tooltip-add-poi"
                  role="tooltip"
                  className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                >
                  Añadir punto de interés
                  <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
              </div>
              {pointsOfInterest?.length > 0 ? (
                pointsOfInterest.map((poi) => (
                  <CardPois
                    key={poi.id}
                    className={"my-2 sm:max-lg:mx-2"}
                    statSubtitle={poi.name}
                    statTitle={poi.description}
                    statPercent={calificacionEstrellas(poi.rating!)}
                    onClick={() => setSelectedPOI(poi)}
                  />
                ))
              ) : (
                <ARTypography value={"Reseñas no encontradas"} />
              )}
            </div>
          </div>
        ) : (
          <div className="w-full h-full items-center justify-center">
            <ARCard className="rounded shadow-lg w-full h-full">
              <ARCardActions className={"flex p-3 items-end"}>
                <button
                  className="text-2xl"
                  onClick={() => {
                    setSelectedPOI(null);
                  }}
                >
                  <i className="fas fa-arrow-left" />
                </button>
                <ARTypography size="lg" sx="ml-2 text-bold">
                  {selectedPOI?.name}
                </ARTypography>
              </ARCardActions>
              <PoiDetail poi={selectedPOI} />
            </ARCard>
          </div>
        )}
      </div>

      {openAddPoi && <ModalAddPoi open={openAddPoi} setOpen={setOpenAddPoi} />}
    </PrivateRoute>
  );
};
