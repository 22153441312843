import React, { useState } from "react";
import { IUser } from "../../interfaces/user";
import { ARTypography } from "../CustomComponents/ARTypography";
import { createPopper } from "@popperjs/core";
import { useNavigate } from "react-router-dom";
import { links } from "../../App";

export const CardProfile = ({
  userInfo,
  children,
}: {
  userInfo?: IUser;
  children?: any;
}) => {
  const navigate = useNavigate();

  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef<HTMLAnchorElement>();
  const popoverDropdownRef = React.createRef<HTMLDivElement>();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current!, popoverDropdownRef.current!, {
      placement: "left-end",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  return (
    <>
      <a
        className="hover:text-blueGray-500 text-blueGray-700 px-3 flex items-center text-4xl uppercase font-bold"
        target="_blank"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <i className="fas fa-user-circle text-white"></i>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") + "bg-white z-50 shadow "
        }
        style={{ transform: "translate(0px, 128px)" }}
      >
        <div className="flex flex-wrap justify-center">
          <div className="w-full px-4 text-center mt-10">
            <div className="text-center">
              <ARTypography
                size="base"
                sx="font-light leading-relaxed text-blueGray-700"
                value={userInfo?.name}
              />
              <ARTypography
                size="base"
                sx="font-light leading-relaxed text-blueGray-700"
                value={userInfo?.email}
              />
              <ARTypography
                size="base"
                sx="font-light leading-relaxed text-blueGray-700"
                value={userInfo?.role}
                leftIcon="fas fa-briefcase "
              />
            </div>
            <div className="flex justify-center lg:pt-4">
              <div className="mr-4 p-3 text-center">
                <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                  2
                </span>
                <span className="text-sm text-blueGray-400">
                  Reportes Abiertos
                </span>
              </div>
              <div className="mr-4 p-3 text-center">
                <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                  10
                </span>
                <span className="text-sm text-blueGray-400">
                  Reportes Cerrados
                </span>
              </div>
            </div>
            <div>
              <button
                className="mb-2 bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  localStorage.clear();
                  navigate(links.auth.login);
                }}
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
