import axios from "axios";
import { ICatalogo } from "../views/admin/Catalogs";

export const createRegistro = async (
  end: string,
  data: ICatalogo,
  set?: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "post",
      url: process.env.REACT_APP_APPLICATION_BACK + end,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });

    set && set(response.data);
    alert(`Elemento agregado con éxito`);
    window.location.reload();
  } catch (error) {
    alert(`Error al crear el registro: ${error}`);
  }
};

export const updateRegistro = async (
  end: string,
  id: string,
  data: ICatalogo,
  set?: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "put",
      url: process.env.REACT_APP_APPLICATION_BACK + end + "/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { name: data.name },
    });
    set && set(response.data);
    alert(`Elemento actualizado con éxito`);
    window.location.reload();
  } catch (error) {
    alert(`Error al actualizar el registro: ${error}`);
  }
};

export const deleteRegistro = async (
  end: string,
  id: string,
  set?: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "delete",
      url: process.env.REACT_APP_APPLICATION_BACK + end + "/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    set && set(response.data);
    alert(`Elemento eliminado con éxito`);
    window.location.reload();
  } catch (error) {
    alert(`Error al eliminar el registro: ${error}`);
  }
};
