import React, { useEffect, useState } from "react";
import { getData } from "../../apis/Get";
import { ICatalogo } from "../../views/admin/Catalogs";
import { ARInput } from "../CustomComponents/ARInput";
import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import { ARModalHeader } from "../CustomComponents/ARModal/ARModalHeader";
import { ARSelect } from "../CustomComponents/ARSelect";
import { IPointOfInterest } from "../POIs/PoiReviews";
import { createPoi } from "../../apis/Pois";

interface ModalAddPoiDescriptionProps {
  open: boolean;
  setOpen: Function;
  poiInfo: IPointOfInterest;
  setPoiInfo: Function;
}

const ModalAddPoiDescription: React.FC<ModalAddPoiDescriptionProps> = ({
  open,
  setOpen,
  poiInfo,
  setPoiInfo,
}) => {
  const [categories, setCategories] = useState<ICatalogo[]>([]);
  const [types, setTypes] = useState<ICatalogo[]>([]);

  const handleAccept = () => {
    if (poiInfo) {
      createPoi(poiInfo);
      // action();
      // setOpen(false);
    }
  };

  const handleChange =
    (field: keyof IPointOfInterest) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setPoiInfo({
        ...poiInfo,
        [field]: e.target.value,
      });
    };

  useEffect(() => {
    getData("/admin/pointcategories", setCategories);
    getData("/admin/pointtypes", setTypes);
  }, [open]);

  return (
    <React.Fragment>
      <ARModal open={open}>
        <ARModalHeader>Nuevo Punto de Interés</ARModalHeader>
        <ARModalBody>
          <ARInput
            value={poiInfo.name}
            label={"Nombre del Punto de interés"}
            onChange={handleChange("name")}
            size="lg"
          />
          <ARInput
            value={poiInfo.description}
            label={"Descripción"}
            onChange={handleChange("description")}
            size="lg"
          />
          <ARSelect
            label="Categoría"
            options={categories}
            value={poiInfo.category_id}
            onChange={(e) => {
              handleChange("category")(e);
              handleChange("category_id")(e);
            }}
            size="lg"
          />
          <ARSelect
            label="Tipo"
            options={types}
            value={poiInfo.point_of_interest_type_id}
            onChange={(e) => {
              handleChange("type")(e);
              handleChange("point_of_interest_type_id")(e);
            }}
            size="lg"
          />
          <div className="flex justify-around">
            <ARInput
              value={poiInfo.latitude}
              label={"Latitud"}
              disabled
              size="sm"
            />
            <ARInput
              value={poiInfo.longitude}
              label={"Longitud"}
              disabled
              size="sm"
            />
          </div>
        </ARModalBody>
        <ARModalActions>
          <button
            type="button"
            className="w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 ml-3 w-auto"
            onClick={() => {
              setOpen(false);
            }}
          >
            {"Cancelar"}
          </button>
          <button
            type="button"
            className="w-full justify-center rounded-md bg-green-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-green-500 ml-3 w-auto"
            onClick={handleAccept}
          >
            {"Aceptar"}
          </button>
        </ARModalActions>
      </ARModal>
    </React.Fragment>
  );
};

export default ModalAddPoiDescription;
