import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { links } from "../App";
import Navbar from "../components/Navbars/IndexNavbar";

const PrivateRoute = ({ children }: { children: any }) => {
  const apiUrl = process.env.REACT_APP_APPLICATION_BACK;
  const [isValidToken, setIsValidToken] = useState<boolean | null>(null);
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios.post(
          apiUrl + "/validate-token",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsValidToken(response.data.isValid);
      } catch (error) {
        setIsValidToken(false);
        navigate(links.auth.login);
      }
    };

    if (token) {
      validateToken();
    } else {
      setIsValidToken(false);
    }
  }, [token]);

  if (isValidToken === null) {
    return (
      <>
        <Navbar />
        <div className="w-full h-full fixed top-0 left-0 opacity-75 z-50">
          <div className="flex justify-center items-center mt-[50vh]">
            <div className="fas fa-circle-notch fa-spin fa-5x text-blueGray-800"></div>
          </div>
        </div>
      </>
    );
  }

  return isValidToken ? children : <Navigate to="/" />;
};

export default PrivateRoute;
