import React from "react";
import CardLineChart from "../../components/Cards/CardLineChart";
import CardSocialTraffic from "../../components/Cards/CardSocialTraffic";
import PrivateRoute from "../../layouts/PrivateRoute";
import Navbar from "../../components/Navbars/IndexNavbar";

// components

export default function Dashboard() {
  return (
    <PrivateRoute>
      {/* <Admin titulo="Dasboard"> */}
      <Navbar />
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <CardLineChart />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardSocialTraffic />
        </div>
      </div>
      {/* </Admin> */}
    </PrivateRoute>
  );
}
