import React, { useEffect, useState } from "react";
import { IPointOfInterest, IRating, IReview } from "./PoiReviews";
import { getImages } from "../../apis/Pois";

interface PoiDetailProps {
  poi: IPointOfInterest;
}

const estrellas = (num: number | string) => "⭐".repeat(Number(num));

const ReviewList: React.FC<{
  ratings: IRating[];
  poiInfo: IPointOfInterest;
}> = ({ ratings }) => {
  return (
    <div className="w-full">
      <div className="flex items-center">
        <h3 className="text-lg font-semibold">Reseñas</h3>
      </div>

      {ratings.length > 0 && (
        <div className="flex flex-wrap w-full h-full">
          {ratings?.map((rate) => (
            <div className="flex flex-col w-[23%] border-2 border-gray-200 items-center justify-center m-2">
              <ReviewImage path={rate.photo_path} />
              <p>{rate.review}</p>
              <p className="text-sm text-gray-500">
                {`Calificación: ${estrellas(rate.rating)}`}
              </p>
              <p
                className={`${
                  rate.estatus.name === "Por revisar"
                    ? "text-blue-500"
                    : rate.estatus.name === "En Proceso"
                    ? "text-yellow-500"
                    : rate.estatus.name === "Resuelto"
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {rate.estatus.name}
              </p>
              <div className="flex items-center justify-evenly">
                <div className="group flex relative">
                  <button
                    type="button"
                    className="text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-2xl p-2.5 text-center inline-flex items-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800 dark:hover:bg-red-500"
                    onClick={() => {
                      // setStatus(rate?.estatus!);
                    }}
                  >
                    <i className="far fa-times-circle"></i>
                  </button>
                  <span className="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2-translate-x-1/2 translate-y-3/4 opacity-0 m-4 mx-auto z-50">
                    Rechazar reseña
                  </span>
                </div>

                <div className="group flex relative">
                  <button
                    type="button"
                    className="text-green-700 border border-green-700 hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-full text-2xl p-2.5 text-center inline-flex items-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:focus:ring-green-800 dark:hover:bg-green-500"
                    onClick={() => {
                      // setStatus(rate?.estatus!);
                    }}
                  >
                    <i className="far fa-check-circle"></i>
                  </button>
                  <span className="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2-translate-x-1/2 translate-y-3/4 opacity-0 m-4 mx-auto z-50">
                    Aceptar reseña
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const ReviewImage = ({ path }: { path: string }) => {
  const [image, setImage] = useState([
    "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
    "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
  ]);
  // useEffect(() => {
  //   getImages(`/${path}`);
  // }, [path]);

  return (
    <img
      src={process.env.REACT_APP_APPLICATION_STORAGE+'/'+path}
      alt={`Foto`}
      className="h-64 w-auto mx-4 object-cover rounded-lg"
    />
  );
};

const PoiDetail: React.FC<PoiDetailProps> = ({ poi }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [photos, setPhotos] = useState([
    "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
    "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
  ]);

  const [image, setImage] = useState(
    "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80"
  );

  useEffect(() => {
    // getImages(`/${poi?.photos && poi?.photos[0]?.photo_path}`, (r: any) => {
    //   setImage(r);
    // });
    if (poi?.photos && poi?.photos[0]?.photo_path) {
      const path = `${process.env.REACT_APP_APPLICATION_STORAGE}/${poi.photos[0].photo_path}`;
      setImage(path);
    }
  }, [poi]);

  if (!poi) return null;

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === photos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="w-full h-full">
      <div className="mb-8 rounded shadow-lg h-[20%]">
        <img
          src={image}
          alt={poi.name}
          className="w-full h-48 object-cover mb-2 rounded"
        />
      </div>

      <div className="justify-between bg-white h-[70%]">
        <p className="text-gray-700 mb-2">{poi.description}</p>
        <p className="text-gray-500 mb-4">
          Ubicación: {poi.latitude}, {poi.longitude}
        </p>
        <ReviewList ratings={poi.ratings!} poiInfo={poi} />
      </div>
    </div>
  );
};

export default PoiDetail;
