import { IInput } from "../../interfaces/IInput";
import { SizeTokens } from "../../styling/Size";

export const ARInput = ({
  label = "",
  defaultValue = "",
  value = undefined,
  helperText = "",
  onChange,
  size = "m",
  type = "text",
  sx,
  disabled,
  error,
}: IInput) => {
  return (
    <div className={`${sx} ${SizeTokens.Input[size]} px-4`}>
      <div className="relative w-full mb-3">
        <label
          className={`block uppercase ${
            error ? "text-red-600" : "text-blueGray-600"
          } text-xs font-bold mb-2`}
          htmlFor="grid-password"
        >
          {label}
        </label>

        {defaultValue ? (
          <input
            type={type}
            className={`border-0 px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
              disabled ? "bg-gray-200 text-gray-500 cursor-not-allowed" : ""
            }`}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
          />
        ) : (
          <input
            type={type}
            className={`border-0 px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
              disabled ? "bg-gray-200 text-gray-500 cursor-not-allowed" : ""
            }`}
            value={value!}
            onChange={onChange}
            disabled={disabled}
          />
        )}

        <small
          id="emailHelp"
          className={`form-text text-muted text-xs ${
            error ? "text-red-600" : "text-blueGray-600"
          } `}
        >
          {helperText}
        </small>
      </div>
    </div>
  );
};
