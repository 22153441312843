import { useEffect, useState } from "react";
import { CardSettings } from "../components/Cards/CardSettings";
import Navbar from "../components/Navbars/IndexNavbar";
import { IUser } from "../interfaces/user";
import { Navigate } from "react-router-dom";
import { links } from "../App";
import PrivateRoute from "../layouts/PrivateRoute";

export default function Profile() {
  const [userInfo, setUserInfo] = useState<IUser>({
    id: localStorage.getItem("userId")!,
    name: localStorage.getItem("user_name")!,
    email: localStorage.getItem("email")!,
    role: localStorage.getItem("userRole")!,
    role_id: localStorage.getItem("userRoleId")!,
  });

  const [newUserInfo, setNewUserInfo] = useState<IUser>(userInfo);

  return (
    <PrivateRoute>
      <Navbar />
      <div className="bg-gray-100 flex items-center justify-center min-h-screen">
        <div className="w-full lg:w-8/12 px-4">
          <CardSettings
            newUserInfo={newUserInfo}
            setNewUserInfo={setNewUserInfo}
          />
        </div>
      </div>
    </PrivateRoute>
  );
}
