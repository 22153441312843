import React from "react";
import { ARCard } from "../../components/CustomComponents/ARCard/ARCard";
import { ARCardBody } from "../../components/CustomComponents/ARCard/ARCardBody";
import Navbar from "../../components/Navbars/IndexNavbar";
import { Link } from "react-router-dom";
import { links } from "../../App";
import { ICatalog } from "../../components/POIs/PoiCatalogs";
import PrivateRoute from "../../layouts/PrivateRoute";

const catalogs: ICatalog[] = [
  {
    catalogo: "Tipos de reporte",
    fnc: "reporttypes",
    api: "/admin/reporttypes",
  },
  { catalogo: "Estatus", fnc: "reportstatus", api: "/admin/reportstatus" },
  {
    catalogo: "Prioridad",
    fnc: "reportpriorities",
    api: "/admin/reportpriorities",
  },
  { catalogo: "Usuarios", fnc: "user", api: "/user" },
];

// Componente para un elemento de catálogo
const CatalogItem: React.FC<ICatalog> = ({ catalogo, fnc, api }) => {
  const linkTo = `${
    catalogo === "Usuarios" ? links.admin.users : links.admin.catalogs
  }?catalog=${catalogo}&get=${api}`;

  return (
    <Link
      to={linkTo}
      className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent"
    >
      <div className="p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-200">
        <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
          {catalogo}
        </h5>
      </div>
    </Link>
  );
};

const Settings: React.FC = () => {
  return (
    <PrivateRoute>
      <Navbar />
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <ARCard>
          <ARCardBody>
            <div className="container mx-auto px-4 sm:px-8 py-8">
              <h2 className="text-2xl font-semibold leading-tight">
                Configuración
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 px-4 py-4">
                {catalogs.map((catalog) => (
                  <CatalogItem key={catalog.fnc} {...catalog} />
                ))}
              </div>
            </div>
          </ARCardBody>
        </ARCard>
      </div>
    </PrivateRoute>
  );
};

export default Settings;
