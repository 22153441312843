import React, { useState, useMemo, useEffect } from "react";
import Navbar from "../../components/Navbars/IndexNavbar";
import { ARCard } from "../../components/CustomComponents/ARCard/ARCard";
import { ARCardBody } from "../../components/CustomComponents/ARCard/ARCardBody";
import ModalViewReportMap from "../../components/Modals/ModalViewReportMap";
import ARIconButton from "../../components/CustomComponents/ARIconButton";
import ModalUserAction from "../../components/Modals/ModalUserAction";
import { ARCardActions } from "../../components/CustomComponents/ARCard/ARCardActions";
import { getData } from "../../apis/Get";
import PrivateRoute from "../../layouts/PrivateRoute";

// IActions {
//   1: "create";
//   2: 'edit';
//   3: 'eliminate';
// }

export interface IUsuario {
  id: string;
  name: string;
  email: string;
  password?: string;
  password_confirmation?: string;
  role_id?: string;
  username: string;
  last_name: string;
  phone: string;
  phone_verify: string;
  photo: string;
  role?: { id: string; name: string };
  created_at: string;
  updated_at: string;
}

export const columns = {
  id: "ID",
  name: "Nombre",
  username: "Usuario",
  email: "Correo electrónico",
  role: "Rol",
  phone: "Teléfono",
  phone_verify: "Verificación Telefónica",
  // photo: "Foto",
};

const Users: React.FC = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const api = urlParams.get("get") || "";

  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [usuario, setUsuario] = useState<IUsuario>({
    id: "",
    name: "",
    last_name: "",
    username: "",
    email: "",
    role: { id: "", name: "" },
    phone: "",
    phone_verify: "",
    photo: "",
    created_at: "",
    updated_at: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [openUserAction, setOpenUserAction] = useState<boolean>(false);
  const [action, setAction] = useState<number>(1);
  const [columnFilter, setColumnFilter] = useState<boolean>(false);

  const filteredUsuarios = useMemo(() => {
    return usuarios.filter((usuario) =>
      usuario.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [usuarios, searchTerm]);

  useEffect(() => {
    getData(api, setUsuarios);
  }, []);

  const handleUserAction = (actionType: number, selectedUser: IUsuario) => {
    setAction(actionType);
    setUsuario(selectedUser);
    setOpenUserAction(true);
  };

  return (
    <PrivateRoute>
      <Navbar />
      <div className="flex align-center justify-center items-center w-full">
        <ARCard className="w-3/4">
          <ARCardActions>
            <h2 className="text-2xl font-semibold leading-tight">Usuarios</h2>
            <div className="flex flex-row items-center">
              <div className="block relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <i className="far fa-search" />
                </span>
                <input
                  placeholder="Buscar"
                  className="appearance-none rounded-l border border-gray-400 block pl-8 pr-6 py-2 w-60 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <ARIconButton
                icon="fas fa-filter fa-lg"
                onClick={() => {
                  setColumnFilter(!columnFilter);
                }}
                sx={`${
                  columnFilter ? "text-black-300" : "text-gray-400"
                } bg-gray-200 hover:bg-gray-300`}
              />
              <ARIconButton
                icon="fas fa-user-plus"
                onClick={() => {
                  setAction(1);
                  setUsuario({
                    id: "",
                    username: "",
                    name: "",
                    last_name: "",
                    email: "",
                    role: { id: "", name: "" },
                    phone: "",
                    phone_verify: "",
                    photo: "",
                    created_at: "",
                    updated_at: "",
                  });
                  setOpenUserAction(true);
                }}
                sx={`bg-green-200 hover:bg-green-300`}
              />
            </div>
          </ARCardActions>
          <ARCardBody className="overflow-auto h-[70vh] overflow-scroll">
            <table className="w-full">
              <thead>
                <tr>
                  {Object.values(columns).map((value: string) => (
                    <th
                      key={value}
                      className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      <p className="text-black-900 whitespace-no-wrap p-1">
                        {value}
                      </p>
                    </th>
                  ))}
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Acciones
                  </th>
                </tr>
              </thead>
              {/* {columnFilter && (
                <tr>
                  {Object.keys(columns).map((column) => (
                    <th
                      key={column}
                      className="px-1 py-1 border-b-2 border-gray-200 bg-gray-100 text-xs text-gray-600"
                    >
                      <input placeholder={`Filtrar ${column}`} />
                    </th>
                  ))}
                </tr>
              )} */}
              <tbody>
                {filteredUsuarios.map((usuario: any) => (
                  <tr key={usuario.id}>
                    {Object.keys(columns).map((key: string) => (
                      <td
                        key={key}
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                      >
                        <p className="text-black-900 whitespace-no-wrap p-1">
                          {key === "role"
                            ? usuario.role.name
                            : key === "name"
                            ? usuario.name + " " + (usuario.last_name || "")
                            : usuario[key]}
                        </p>
                      </td>
                    ))}
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <ARIconButton
                        icon="fas fa-user-edit"
                        onClick={() => handleUserAction(2, usuario)}
                        sx="bg-blue-300 hover:bg-blue-400"
                      />
                      <ARIconButton
                        icon="fas fa-user-slash"
                        onClick={() => handleUserAction(3, usuario)}
                        sx="bg-red-300 hover:bg-red-400"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ARCardBody>
        </ARCard>
      </div>
      <ModalViewReportMap open={open} setOpen={setOpen} />
      <ModalUserAction
        open={openUserAction}
        setOpen={setOpenUserAction}
        user={usuario}
        action={action}
      />
    </PrivateRoute>
  );
};

export default Users;
