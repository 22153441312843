import React from "react";
import PropTypes from "prop-types";
import { IReport } from "../../interfaces/IReports";

interface CardStatsProps {
  data?: IReport;
  statDescription?: string;
  statIconName: string;
  statIconColor: string;
  onClick?: Function;
  onIconClick?: Function;
  className?: any;
}

const CardStats: React.FC<CardStatsProps> = ({
  data,
  statDescription = "",
  statIconName,
  statIconColor,
  onClick,
  className,
  onIconClick,
}) => {
  const date = new Date(data?.created_at!);
  const dateOnly = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;

  return (
    <div
      className={`${className} w-full bg-white rounded mb-6 shadow-lg cursor-pointer`}
      onClick={() => onClick && onClick()}
    >
      <div className="flex-auto p-4">
        <div className="items-center justify-center">
          <div className="flex w-full justify-between">
            <h5 className="text-blueGray-400 uppercase font-bold text-xs text-wrap">
              {`${data?.title} - ${dateOnly}`}
            </h5>
            <div
              className={`text-white text-center inline-flex items-center justify-center sm:w-8 sm:h-6 lg:w-9 lg:h-9 shadow-lg rounded-full ${statIconColor}`}
            >
              <i
                className={statIconName}
                onClick={() => onIconClick && onIconClick()}
              ></i>
            </div>
          </div>
          <span className="font-semibold sm:text-xs lg:text-lg text-blueGray-700">
            {data?.description}
          </span>
        </div>

        <p className="text-sm text-blueGray-400 lg:mt-4">
          <span
            className={`${
              data?.status.name === "Solicitud"
                ? "text-blue-500"
                : data?.status.name === "En Proceso"
                ? "text-yellow-500"
                : data?.status.name === "Resuelto"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {data?.status.name}
          </span>
          {data?.priority?.name &&
            !["Cancelado", "Resuelto"].includes(data.status.name) && (
              <>
                {" "}
                - Prioridad:{" "}
                <span className={`text-emerald-500 mr-2`}>
                  {`${data?.priority?.name} `}
                  <i className={`fas fa-arrow-up`} />
                </span>
              </>
            )}
          <span className="whitespace-nowrap">{statDescription}</span>
        </p>
      </div>
    </div>
  );
};

CardStats.propTypes = {
  statDescription: PropTypes.string,
  statIconName: PropTypes.string.isRequired,
  statIconColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired, // Aseguramos que onClick sea una función requerida
};

export default CardStats;
