import { useState } from "react";
import { ARInput } from "../CustomComponents/ARInput";
import { ARTypography } from "../CustomComponents/ARTypography";
import { IUser } from "../../interfaces/user";
import ModalChangePassword from "../Modals/ModalChangePassword";

export const CardSettings = ({
  newUserInfo,
  setNewUserInfo,
}: {
  newUserInfo: IUser;
  setNewUserInfo: Function;
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
      <div className="rounded-t bg-white mb-0 px-6 py-6">
        <div className="text-center flex justify-between">
          <ARTypography
            sx="text-blueGray-700 text-xl font-bold"
            value={"Mi Cuenta"}
          />
        </div>
      </div>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0 place-items-center">
        <form className="place-items-center">
          <ARTypography
            sx="text-blueGray-400 mt-3 mb-6 font-bold uppercase"
            value={"Información del usuario"}
          />
          <div className="md:flex align-center place-items-center">
            <ARInput
              label={"Nombre"}
              value={newUserInfo.name}
              onChange={() => {}}
              size="lg"
            />
            <ARInput
              label={"Correo Electrónico"}
              value={newUserInfo.email}
              onChange={() => {}}
              size="lg"
            />
            <ARInput
              label={"Rol"}
              value={newUserInfo.role}
              onChange={() => {}}
              size="lg"
            />
          </div>
          <button
            className="mt-8 bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Cambiar contraseña
          </button>
        </form>

        <ModalChangePassword open={openModal} setOpen={setOpenModal} />
      </div>
    </div>
  );
};
