import axios from "axios";
import { IUsuario } from "../views/admin/Users";

export const createUsuario = async (data: IUsuario, set?: Function) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "post",
      url: process.env.REACT_APP_APPLICATION_BACK + "/user",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });

    set && set(response.data);
    alert(`Usuario creado con éxito: ${response.data}`);
    window.location.reload();
  } catch (error) {
    alert(`Error al crear el usuario: ${error}`);
  }
};

export const updateUsuario = async (
  id: string,
  data: IUsuario,
  set?: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_APPLICATION_BACK + "/user/" + id + "/edit",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    set && set(response.data);
    alert(`Usuario actualizado con éxito: ${response.data}`);
    window.location.reload();
  } catch (error) {
    alert(`Error al actualizar el usuario: ${error}`);
  }
};

export const deleteUsuario = async (id: string, set?: Function) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "delete",
      url: process.env.REACT_APP_APPLICATION_BACK + "/user/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: id,
      },
    });
    set && set(response.data);
    alert(`Usuario eliminado con éxito: ${response.data}`);
    window.location.reload();
  } catch (error) {
    alert(`Error al eliminar el usuario: ${error}`);
  }
};
