import L, { DivIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { getData } from "../../apis/Get";
import CardStats from "../../components/Cards/CardStats";
import { ARTypography } from "../../components/CustomComponents/ARTypography";
import Navbar from "../../components/Navbars/IndexNavbar";
import PrivateRoute from "../../layouts/PrivateRoute";
import ModalViewReportMap from "../../components/Modals/ModalViewReportMap";
import { IReport } from "../../interfaces/IReports";

export interface IMarkerData {
  id: string;
  title: string;
  description: string;
  report_group_id: string;
  status: { id: string; name: string };
  priority: { id: string; name: string };
  user: {
    id: string;
    name: string;
    username: string;
    role: { id: string; name: string };
  };
  type: { id: string; name: string; icon: any };
  latitude: number;
  longitude: number;
  created_at: string;
  updated_at: string;
}

export const createIcon = (color: string, iconClass: string): DivIcon =>
  L.divIcon({
    html: `<span class="fa-stack small text-${color}"><i class="fas fa-circle fa-stack-2x"></i><i class="fas ${iconClass} fa-stack-1x fa-inverse"></i></span>`,
  });

export const mapIcons = {
  light: createIcon("lightBlue-500", "fa-traffic-light"),
  crash: createIcon("red-500", "fa-car-crash"),
  road: createIcon("emerald-500", "fa-road"),
  traffic: createIcon("orange-500", "fa-chart-pie"),
};

const Maps = () => {
  const [markersData, setMarkersData] = useState<IReport[]>([]);
  const [selectedReport, setSelectedReport] = useState<IReport>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [visibleIcons, setVisibleIcons] = useState<Record<string, boolean>>({
    light: true,
    crash: true,
    road: true,
    traffic: true,
  });

  useEffect(() => {
    getData("/reports", setMarkersData);
  }, []);

  const toggleIconVisibility = (icon: keyof typeof visibleIcons) => {
    setVisibleIcons((prev) => ({ ...prev, [icon]: !prev[icon] }));
  };

  return (
    <PrivateRoute>
      <Navbar />
      <div
        className="lg:flex sm:flex-row w-full items-center justify-center"
        style={{ height: "90vh" }}
      >
        <div className="min-[320px]:flex lg:flex-col min-[320px]:w-full lg:w-1/4 min-[320px]:h-1/5 lg:h-3/4 overflow-auto">
          {markersData.length > 0 ? (
            markersData.map((marker) => (
              <CardStats
                key={marker.id}
                data={marker}
                className={"sm:max-lg:mx-2"}
                statIconName={`fas ${
                  marker.type.name.includes("001")
                    ? "lightBlue-500 fa-traffic-light"
                    : marker.type.name.includes("002")
                    ? "red-500 fa-car-crash"
                    : marker.type.name.includes("003")
                    ? "orange-500 fa-chart-pie"
                    : "emerald-500 fa-road"
                }`}
                statIconColor={`${
                  marker.type.name.includes("001")
                    ? "bg-lightBlue-500"
                    : marker.type.name.includes("002")
                    ? "bg-red-500"
                    : marker.type.name.includes("003")
                    ? "bg-orange-500"
                    : "bg-emerald-500"
                }`}
                onIconClick={() => {
                  toggleIconVisibility(
                    marker.type.name.includes("luces")
                      ? "light"
                      : marker.type.name.includes("002")
                      ? "crash"
                      : marker.type.name.includes("003")
                      ? "traffic"
                      : "road"
                  );
                }}
                onClick={() => {
                  setSelectedReport(marker);
                  setIsModalOpen(true);
                }}
              />
            ))
          ) : (
            <ARTypography value={"Sin incidentes reportados"} />
          )}
        </div>

        {isModalOpen && (
          <ModalViewReportMap
            open={isModalOpen}
            setOpen={setIsModalOpen}
            report={selectedReport}
          />
        )}

        <div className="lg:w-8/12 sm:w-full h-3/4 flex-center">
          <MapContainer
            style={{ height: "100%", zIndex: "0" }}
            center={[19.469391, -99.594048]}
            zoom={11}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markersData.map((marker) =>
              visibleIcons[
                marker.title.toLocaleLowerCase().includes("001")
                  ? "light"
                  : marker.title.toLocaleLowerCase().includes("002")
                  ? "crash"
                  : marker.title.toLocaleLowerCase().includes("003")
                  ? "traffic"
                  : "road"
              ] ? (
                <Marker
                  key={marker.id}
                  position={[Number(marker.latitude), Number(marker.longitude)]}
                  icon={
                    marker.title.toLocaleLowerCase().includes("001")
                      ? mapIcons["light"]
                      : marker.title.toLocaleLowerCase().includes("002")
                      ? mapIcons["crash"]
                      : marker.title.toLocaleLowerCase().includes("003")
                      ? mapIcons["traffic"]
                      : mapIcons["road"]
                  }
                >
                  <Popup>
                    <div>
                      <strong>{marker.title}</strong>
                      <br />
                      {marker.description}
                    </div>
                  </Popup>
                </Marker>
              ) : null
            )}
          </MapContainer>
        </div>
      </div>
    </PrivateRoute>
  );
};

export default Maps;
