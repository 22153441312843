import axios from "axios";

export const updatePassword = async (
  idUsuario: string,
  oldPassword: string,
  newPassword: string,
  set?: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "post",
      url:
        process.env.REACT_APP_APPLICATION_BACK +
        "/user/changePassword/" +
        idUsuario,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
    });

    set && set(response.data);
    alert(`Contraseña cambiada con éxito!`);
    window.location.reload();
  } catch (error) {
    alert(`Error al crear el registro: ${error}`);
  }
};
