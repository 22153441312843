import axios from "axios";
import { IReport } from "../interfaces/IReports";

export const getReportData = async (id: string | undefined, set: Function) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      process.env.REACT_APP_APPLICATION_BACK + "/reports/" + id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    set(response.data);
  } catch (error) {
    console.error("Error fetching markers data:", error);
  }
};

export const changeReportStatus = async (
  id: string,
  data: IReport,
  set?: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "put",
      url: process.env.REACT_APP_APPLICATION_BACK + "/reports/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
    set && set(response.data);
    alert(`Elemento actualizado con éxito`);
    window.location.reload();
  } catch (error) {
    alert(`Error al actualizar el registro: ${error}`);
  }
};
