import { ITypography } from "../../interfaces/ITypography";
import { SizeTokens } from "../../styling/Size";

export const ARTypography = ({
  value,
  size = "xs",
  sx,
  leftIcon,
  rightIcon,
  tooltip,
  children,
}: ITypography) => {
  const renderIcon = (iconClass: string | undefined) => {
    return typeof iconClass === "string" ? (
      <i className={`${iconClass} mr-2 text-lg text-blueGray-400`}></i>
    ) : null;
  };
  return (
    <div
      className={`${
        leftIcon || rightIcon ? "flex flex-wrap justify-center" : ""
      }`}
      style={{ textWrap: "nowrap" }}
    >
      {renderIcon(leftIcon)}
      <p className={`${sx || ""} ${SizeTokens.Typography[size] || ""}`.trim()}>
        {value || children || ""}
      </p>
      {renderIcon(rightIcon)}
    </div>
  );
};
