import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";
import { ARModalHeader } from "../CustomComponents/ARModal/ARModalHeader";
import { ARTypography } from "../CustomComponents/ARTypography";
import { FC, useState, useEffect } from "react";
import { IUsuario } from "../../views/admin/Users";
import { ARInput } from "../CustomComponents/ARInput";
import { ARSelect } from "../CustomComponents/ARSelect";
import { createUsuario, deleteUsuario, updateUsuario } from "../../apis/Users";
import { getData } from "../../apis/Get";

interface ModalUserActionProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  user?: IUsuario;
  action?: number;
}

const ModalUserAction: FC<ModalUserActionProps> = ({
  open,
  setOpen,
  user,
  action,
}) => {
  const [newValue, setNewValue] = useState<IUsuario>({
    id: "",
    username: "",
    name: "",
    last_name: "",
    email: "",
    role_id: "",
    password: "",
    password_confirmation: "",
    phone: "",
    phone_verify: "",
    photo: "",
    created_at: "",
    updated_at: "",
  });

  const [roles, setRoles] = useState<
    Array<{ id: string | number; value: string | number }>
  >([]);

  useEffect(() => {
    if (user) {
      setNewValue(user);
    }
  }, [user]);

  const handleAccept = () => {
    if (!newValue.name || !newValue.email) {
      alert("Por favor llena todos los campos");
      return;
    }
    if (newValue.password !== newValue.password_confirmation) {
      alert("Las contraseñas no coinciden");
      return;
    }
    if (action === 1) {
      createUsuario(newValue);
    } else if (action === 2) {
      updateUsuario(newValue.id, newValue);
    } else if (action === 3) {
      deleteUsuario(newValue.id);
    } else {
      alert("Acción inválida");
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange =
    (field: keyof IUsuario) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setNewValue((prevValue) => ({ ...prevValue, [field]: e.target.value }));
    };

  useEffect(() => {
    getData("/admin/roles", setRoles);
  }, []);

  return (
    <ARModal open={open} setOpen={setOpen}>
      <ARModalHeader>
        <ARTypography
          value={`${
            action === 1 ? "Crear" : action === 2 ? "Editar" : "Eliminar"
          } usuario`}
        />
      </ARModalHeader>
      <ARModalBody sx="grid grid-cols-2 justify-center justify-items-center">
        {action !== 1 && (
          <ARInput
            label="ID"
            value={newValue.id}
            sx="w-full"
            size="lg"
            disabled
          />
        )}

        <ARInput
          label="Username"
          value={newValue.username}
          onChange={handleChange("username")}
          sx="w-full"
          size="lg"
          disabled={[2, 3].includes(action!)}
        />
        <ARInput
          label="Nombre"
          value={newValue.name}
          onChange={handleChange("name")}
          sx="w-full"
          size="lg"
          disabled={action === 3}
        />
        <ARInput
          label="Apellido(s)"
          value={newValue.last_name}
          onChange={handleChange("last_name")}
          sx="w-full"
          size="lg"
          disabled={action === 3}
        />
        <ARInput
          label="Correo electrónico"
          value={newValue.email}
          onChange={handleChange("email")}
          sx="w-full"
          size="lg"
          disabled={[2, 3].includes(action!)}
        />
        <ARInput
          label="Teléfono"
          value={newValue.phone}
          onChange={handleChange("phone")}
          sx="w-full"
          size="lg"
          disabled={action === 3}
        />
        <ARInput
          label="Verificar Teléfono"
          value={newValue.phone_verify}
          onChange={handleChange("phone_verify")}
          sx="w-full"
          size="lg"
          disabled={action === 3}
        />
        <ARSelect
          label="Rol"
          options={roles}
          value={newValue.role?.name}
          onChange={(e) => {
            handleChange("role")(e);
            handleChange("role_id")(e);
          }}
          sx="w-full"
          size="lg"
          disabled={action === 3}
        />
        {action === 1 && (
          <ARInput
            label="Contraseña"
            value={newValue.password}
            onChange={(e) => handleChange("password")(e)}
            sx="w-full"
            size="lg"
          />
        )}
        {action === 1 && (
          <ARInput
            label="Confirmar contraseña"
            value={newValue.password_confirmation}
            onChange={(e) => handleChange("password_confirmation")(e)}
            sx="w-full"
            size="lg"
          />
        )}
        <div className={`w-full px-4`}>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="file_input"
            >
              Foto
            </label>
            <input
              className={`border-0 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
              id="file_input"
              type="file"
              onChange={(e) => handleChange("photo")(e)}
              disabled={action === 3}
            />
            <p
              className="mt-1 text-sm text-gray-500 dark:text-gray-300"
              id="file_input_help"
            >
              SVG, PNG, JPG or GIF (MAX. 800x400px).
            </p>
          </div>
        </div>
      </ARModalBody>

      <ARModalActions>
        <button
          type="button"
          className="inline-flex ml-4 justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:w-auto"
          onClick={handleAccept}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={handleCancel}
        >
          Cancelar
        </button>
      </ARModalActions>
    </ARModal>
  );
};

export default ModalUserAction;
