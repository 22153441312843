import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";
import { ARModalHeader } from "../CustomComponents/ARModal/ARModalHeader";
import { ARTypography } from "../CustomComponents/ARTypography";
import { FC, useState, useEffect } from "react";
import { ICatalogo } from "../../views/admin/Catalogs";
import { ARInput } from "../CustomComponents/ARInput";
import {
  createRegistro,
  deleteRegistro,
  updateRegistro,
} from "../../apis/Catalogos";

interface ModalCatalogActionProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  elemento?: ICatalogo;
  action?: number;
  api: string;
}

const ModalCatalogAction: FC<ModalCatalogActionProps> = ({
  open,
  setOpen,
  elemento,
  action,
  api,
}) => {
  const [newValue, setNewValue] = useState<ICatalogo>({
    id: "",
    name: "",
    icon: "",
  });

  useEffect(() => {
    if (elemento) {
      setNewValue(elemento);
    }
  }, [elemento]);

  const handleAccept = () => {
    if (action === 1) {
      createRegistro(api, newValue);
    } else if (action === 2) {
      updateRegistro(api, newValue.id, newValue);
    } else if (action === 3) {
      deleteRegistro(api, newValue.id);
    } else {
      alert("Acción inválida");
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange =
    (field: keyof ICatalogo) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewValue((prevValue) => ({ ...prevValue, [field]: e.target.value }));
    };

  return (
    <ARModal open={open} setOpen={setOpen}>
      <ARModalHeader>
        <ARTypography
          value={`${
            action === 1 ? "Crear" : action === 2 ? "Editar" : "Eliminar"
          } elemento`}
        />
      </ARModalHeader>
      <ARModalBody sx="flex flex-col space-y-4">
        <ARInput
          label="Nombre"
          value={newValue.name}
          onChange={handleChange("name")}
          sx="w-full"
          disabled={action === 3}
        />
        <div className={`w-full px-4`}>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="file_input"
            >
              Icono
            </label>
            <input
              className={`border-0 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150`}
              id="file_input"
              type="file"
              onChange={(e) => handleChange("icon")(e)}
              disabled={action === 3}
            />
            <p
              className="mt-1 text-sm text-gray-500 dark:text-gray-300"
              id="file_input_help"
            >
              SVG, PNG, JPG (MAX. 80x40px).
            </p>
          </div>
        </div>
      </ARModalBody>

      <ARModalActions>
        <button
          type="button"
          className="inline-flex ml-4 justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:w-auto"
          onClick={handleAccept}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={handleCancel}
        >
          Cancelar
        </button>
      </ARModalActions>
    </ARModal>
  );
};

export default ModalCatalogAction;
