export const SizeTokens = {
  Input: {
    xs: "w-3/12",
    sm: "w-4/12",
    m: "w-6/12",
    lg: "w-12/12",
  },
  Select: {
    xs: "lg:w-3/12",
    sm: "lg:w-4/12",
    m: "lg:w-6/12",
    lg: "lg:w-12/12",
  },
  Typography: {
    base: "text-base",
    xs: "text-xl",
    sm: "text-2xl",
    m: "text-3xl",
    lg: "text-4xl",
  },
};
