import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";

import { ARModalHeader } from "../CustomComponents/ARModal/ARModalHeader";
import { ARTypography } from "../CustomComponents/ARTypography";
import { ARInput } from "../CustomComponents/ARInput";
import { useState } from "react";
import { updatePassword } from "../../apis/UserInfo";

export default function ModalChangePassword({ open, setOpen, action }: any) {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState(false);

  const idUsuario = localStorage.getItem("userId")!;

  const cambiarPassword = () => {
    if (!oldPass || !newPass || !confirmPass) setError(true);
    if (oldPass && newPass && confirmPass) {
      if (newPass === confirmPass) {
        updatePassword(idUsuario, oldPass, newPass);
      } else {
        alert("Las contraseñas no coinciden");
      }
    } else {
      alert("Se requiere rellenar todos los campos");
    }
  };

  const endAction = () => {
    setOldPass("");
    setNewPass("");
    setConfirmPass("");
    setError(false);
  };

  return (
    <ARModal open={open} setOpen={setOpen} size="w-1/4">
      <ARModalHeader>
        <ARTypography value={"Cambiar Contraseña"} />
      </ARModalHeader>
      <ARModalBody>
        <ARInput
          label={"Contraseña anterior"}
          value={oldPass}
          onChange={(e) => {
            setError(false);
            setOldPass(e.target.value);
          }}
          size="lg"
          helperText={error && !oldPass ? "Campo requerido" : ""}
          error={error && !oldPass}
        />
        <ARInput
          label={"Contraseña nueva"}
          value={newPass}
          onChange={(e) => {
            setNewPass(e.target.value);
          }}
          size="lg"
          helperText={error && !newPass ? "Campo requerido" : ""}
          error={error && !newPass}
        />
        <ARInput
          label={"Confirmar contraseña"}
          value={confirmPass}
          onChange={(e) => {
            setConfirmPass(e.target.value);
          }}
          size="lg"
          helperText={error && !confirmPass ? "Campo requerido" : ""}
          error={error && !confirmPass}
        />
      </ARModalBody>
      <ARModalActions>
        <button
          type="button"
          className="w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 ml-3 w-auto"
          onClick={() => {
            endAction();
            setOpen(false);
          }}
        >
          {"Cancelar"}
        </button>
        <button
          type="button"
          className="w-full justify-center rounded-md bg-green-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-green-500 ml-3 w-auto"
          onClick={cambiarPassword}
        >
          {"Aceptar"}
        </button>
      </ARModalActions>
    </ARModal>
  );
}
