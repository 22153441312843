import React from "react";
import {
  Chart as ChartJS, CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";

import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Reportes por mes',
    },
  },
};

const labels = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Semana Anterior',
      data: [12, 12, 11, 15, 8, 14, 9],
      backgroundColor: 'rgba(55, 199, 132, 0.5)',
    },
    {
      label: 'Semana Actual',
      data: [10, 3, 14, 16, 17, 15, 15],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export default function CardLineChart() {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                Estadísticas
              </h6>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          <div className="relative h-600-px">
            <Bar options={options} data={data} />
          </div>
        </div>
      </div>
    </>
  );
}
