import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";

import { ARModalHeader } from "../CustomComponents/ARModal/ARModalHeader";
import { ARTypography } from "../CustomComponents/ARTypography";
import { ARSelect } from "../CustomComponents/ARSelect";
import { useEffect, useState } from "react";
import { getData } from "../../apis/Get";

export default function ModalAssignReport({ open, setOpen, action }: any) {
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState({ id: "", name: "" });

  useEffect(() => {
    getData("/user", setUsuarios);
  }, [open]);

  return (
    <ARModal open={open} setOpen={setOpen}>
      <ARModalHeader>
        <ARTypography value={"Asignar Reporte"} />
      </ARModalHeader>
      <ARModalBody>
        <ARSelect
          label="Selecciona usuario al que le deseas asignar el reporte"
          options={usuarios}
          value={usuario.id}
          onChange={(e) => {
            setUsuario({ ...usuario, id: e.target.value });
          }}
          size="lg"
        />
      </ARModalBody>
      <ARModalActions>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => {
            setUsuario({ id: "", name: "" });
            setOpen(false);
          }}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-green px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {}}
        >
          Aceptar
        </button>
      </ARModalActions>
    </ARModal>
  );
}
