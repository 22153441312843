/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "../Sidebar/Sidebar";
import { CardProfile } from "../Cards/CardProfile";

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <>
      <Sidebar open={navbarOpen} setOpen={setNavbarOpen} />
      <nav className="z-50 inset-x-0 top-0 bg-gray-800 text-white flex w-full h-20 items-center">
        <div className="w-full flex items-center px-4 justify-between">
          <div>
            <button
              className="p-4 text-2xl"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars" />
            </button>
            <Link
              to="/"
              className="text-white text-xl font-bold leading-relaxed py-2 whitespace-nowrap uppercase"
            >
              App Reporte
            </Link>
          </div>

          <CardProfile
            userInfo={{
              id: localStorage.getItem("userId")!,
              name: localStorage.getItem("user_name")!,
              email: localStorage.getItem("email")!,
              role_id: localStorage.getItem("userRoleId")!,
              role: localStorage.getItem("userRole")!,
            }}
          />
        </div>
      </nav>
    </>
  );
}
