import { ReactNode } from "react";

import FooterSmall from "../components/Footers/FooterSmall";

type AuthProps = {
  children: ReactNode
}

export default function Auth(props: AuthProps) {
  return (
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("../assets/img/register_bg_2.png").default + ")",
            }}
          ></div>
            {props.children}
          <FooterSmall absolute />
        </section>
      </main>
  );
}
